<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-overlay :show="loading">
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-table-simple bordered>
                        <b-tr>
                            <b-th style="width:25%">{{ $t('globalTrans.name') }}</b-th>
                            <b-th style="width:25%">{{ $t('globalTrans.designation') }}</b-th>
                            <b-th style="width:25%">{{ $t('elearning_config.committee_position') }}</b-th>
                            <b-th style="width:25%">{{ $t('globalUserData.mobile_no') }}</b-th>
                            <b-th style="width:25%">{{ $t('globalTrans.email') }}</b-th>
                        </b-tr>
                        <b-tr v-for="(item,index) in venueMnagement" :key="index">
                            <b-td style="width:20%">{{ ($i18n.locale=='en') ? item.name_en : item.name_bn }}</b-td>
                            <b-td style="width:20%">{{ ($i18n.locale=='en') ? item.designation_en : item.designation_bn }}</b-td>
                            <b-td style="width:20%">{{ ($i18n.locale=='en') ? item.committee_position_en : item.committee_position_bn }}</b-td>
                            <b-td style="width:30%">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(item.mobile_no, { useGrouping: false }) }}</b-td>
                            <b-td style="width:20%">{{ item.email }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td v-if="venueMnagement.length <= 0" colspan="4" class="text-center" style="color:red;">{{$t('globalTrans.noDataFound')}}</b-td>
                        </b-tr>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
export default {
  name: 'FormLayout',
  props: ['venueId', 'orgId'],
  created () {
    this.loadData()
  },
  data () {
    return {
      loading: false,
      venueMnagement: [],
      slOffset: 1
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    loadData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(trainingElearningServiceBaseUrl, 'external-user/venue-reservation/executive-committee/' + this.venueId + '/' + this.orgId).then(response => {
          if (response.success) {
            this.venueMnagement = response.data.details
            this.loading = false
          } else {
            this.venueMnagement = ''
            this.loading = false
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
      // this.loading = false
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
