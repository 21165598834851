<template>
  <div class="main_wrapper">
    <div class="cover_image_wrapper">
      <h2 class="cover_title">{{ $t('portal.request_venue_booking') }}</h2>
      <img src="../../../../assets/images/portal/cover-img.jpg" alt="" />
    </div>
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item active"><a to="#">{{ $t('portal.request_venue_booking') }}</a></li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="menu_bar_wrapper">
      <b-container>
        <b-row>
          <b-col sm="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('portal.request_venue_booking') }}</h4>
              </template>
              <template v-slot:body>
                <div class="all_service_wrapper venue">
                  <b-overlay :show="load">
                    <div>
                      <b-alert show dismissible variant="success" v-if="showMessage">
                        {{$t('globalTrans.we_notify_you_in_message')}} <b>&rArr;</b>
                      </b-alert>
                    </div>
                    <b-alert v-if="!booking" variant="danger" show dismissible fade><b>{{ $t('elearning_venue.this_vanue_is_reserved_in_this_date_and_time') }}</b> </b-alert>
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                      <b-form id="form" @submit.prevent="handleSubmit(otpSend)" @reset.prevent="reset" >
                        <b-row>
                          <!-- <b-col lg="6" sm="6">
                            <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="fiscal_year_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.fiscal_year_id"
                                    :options="fiscalYearList"
                                    id="fiscal_year_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col> -->
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="org_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.org_id"
                                    :options="orgList"
                                    id="org_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="office_type_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.office_type_id"
                                    :options="officeTypeList"
                                    id="office_type_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="office_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.office_id"
                                    :options="officeList"
                                    id="office_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Venue"  vid="venue_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="venue_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.type_of_applicant')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.gov_private"
                                  :options="govOrPvt"
                                  id="gov_private"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <!-- <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option> -->
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Venue"  vid="venue_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="venue_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_tpm.vanue')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.venue_id"
                                  :options="venusDatas"
                                  id="venue_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Total Capacity"  vid="total_capacity" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="total_capacity"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.total_capacity')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                  id="total_capacity"
                                  v-model="formData.total_capacity"
                                  :placeholder="$t('elearning_venue.total_capacity')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Rent Amount"  vid="rent_amount" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="rent_amount"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_venue.rent_amount')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                  id="rent_amount"
                                  v-model="formData.rent_amount"
                                  :placeholder="$t('elearning_venue.rent_amount')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <!-- <b-col lg="6" sm="6">
                            <ValidationProvider name="Contact No"  vid="contact_person_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="contact_person_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.contract_person_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                  id="contact_person_no"
                                  v-model="formData.contact_person_no"
                                  :placeholder="$t('elearning_config.contract_person_no')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  readonly
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col> -->
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Address"  vid="address" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="address"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.address')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-textarea
                                  id="address"
                                  v-model="formData.address"
                                  :placeholder="$t('globalTrans.address')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  v-if="$i18n.locale === 'en'"
                                  disabled
                                ></b-form-textarea>
                                <b-form-textarea
                                  id="address"
                                  v-model="formData.address_bn"
                                  :placeholder="$t('globalTrans.address')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  v-else
                                  disabled
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6" v-if="formData.org_id !== 9">
                            <ValidationProvider name="Electricity Bill"  vid="electricity_bill">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="electricity_bill"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.electricity_bill')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                  id="electricity_bill"
                                  v-model="formData.electricity_bill"
                                  :placeholder="$t('elearning_config.electricity_bill')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6" v-if="gascheck === 1">
                            <ValidationProvider name="Gas Bill"  vid="gas_bill" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="gas_bill"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.gas_bill')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                  id="gas_bill"
                                  v-model="formData.gas_bill"
                                  :placeholder="$t('elearning_config.gas_bill')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="12" sm="12" class="mt-0 pt-0 mb-3" v-if="formData.venue_id && formData.org_id === 12">
                            <!-- <a href="javascript:" class="btn_table_action table_action_view" v-b-modal.modal-5 @click="details(formData.venue_id)"><u>{{$t('portal.click_contract_info')}}</u> <i class="fas fa-eye"></i></a> -->
                            <a href="javascript:" class="btn_table_action table_action_view" v-b-modal.modal-5 @click="details(formData.org_id, formData.venue_id)"><h5 style="color:green;"><u><b>{{$t('portal.click_contract_info')}}</b></u></h5></a>
                          </b-col>
                          <!-- <b-col lg="6" sm="6">
                            <a href="javascript:" class="btn_table_action table_action_view" v-b-modal.modal-5 @click="details(data.item)">{{$t('portal.click_contract_info')}} <i class="fas fa-eye"></i></a>
                          </b-col> -->
                        </b-row>
                        <b-row>
                          <b-col lg="6" sm="6">
                          <ValidationProvider name="Start Date" vid="start_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="start_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.start_date')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  class="fromDate"
                                  v-model="formData.start_date"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="End Date" vid="end_date" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="end_date"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.end_date')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    class="fromDate"
                                    v-model="formData.end_date"
                                    :placeholder="$t('globalTrans.select_date')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col  lg="12" sm="12" v-if="reservationDataList.length > 0" class="text-danger">
                            {{ '***' }} {{ $t('portal.venue_reserve_msg') }} {{ ':' }}
                            <b-table-simple bordered>
                              <b-tr>
                                  <b-th class="text-center" style="width:20%">{{ $t('globalTrans.start_date') }}</b-th>
                                  <b-th class="text-center" style="width:20%">{{ $t('globalTrans.end_date') }}</b-th>
                              </b-tr>
                              <b-tr v-for="(item,index) in reservationDataList" :key="index">
                                  <b-td class="text-center">{{ item.start_date | dateFormat }}</b-td>
                                  <b-td class="text-center">{{ item.end_date | dateFormat }}</b-td>
                              </b-tr>
                            </b-table-simple>
                            <!-- <span v-for="(item,index) in reservationDataList" :key="index">
                              {{ item.start_date}} {{ 'to' }} {{ item.end_date }},
                            </span> -->
                          </b-col>
                          <b-col lg="6" sm="6" v-if="formData.org_id !== 4">
                            <ValidationProvider name="Start Time" vid="start_time" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="start_time"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.start_time')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                  type= "time"
                                  v-model="formData.start_time"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6" v-if="formData.org_id !== 4">
                            <ValidationProvider name="End Time" vid="end_time" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="end_time"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.end_time')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                  type= "time"
                                  v-model="formData.end_time"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6" v-if="formData.org_id === 4">
                            <ValidationProvider name="Slot" vid="slot_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="slot_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('portal.slot')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.slot_id"
                                    :options="slotList"
                                    id="slot_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Reason (En)"  vid="reason"  rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="reason"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.reason_en')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-textarea
                                    rows="2"
                                    id="reason"
                                    v-model="formData.reason"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Reason (Bn)"  vid="reason_bn">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="reason_bn"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.reason_bn')}}
                                </template>
                                <b-form-textarea
                                    rows="2"
                                    id="reason_bn"
                                    v-model="formData.reason_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <fieldset class="p-2 w-100">
                          <legend class="px-2 w-50 shadow-sm">{{$t('elearning_venue.reservation_info')}}</legend>
                          <div class="col-md-12">
                            <b-row>
                              <b-col lg="6" sm="6">
                                <ValidationProvider name="Name (En)"  vid="name" rules="required">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="name"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('globalTrans.name_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                      id="name"
                                      v-model="formData.name"
                                      :placeholder="$t('globalTrans.name_en')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="6">
                                <ValidationProvider name="Name BN"  vid="name_bn" rules="required">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="name_bn"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('globalTrans.name_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                      id="name_bn"
                                      v-model="formData.name_bn"
                                      :placeholder="$t('globalTrans.name_bn')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="6">
                                <ValidationProvider name="Email"  vid="email" rules="required|email|min:3">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="email"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('globalTrans.email')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                      id="email"
                                      v-model="formData.email"
                                      :placeholder="$t('globalTrans.email')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="6">
                                <ValidationProvider name="Mobile"  vid="mobile" rules="required|min:11|max:11">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="mobile"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('globalTrans.mobile')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="number"
                                      id="mobile"
                                      v-model="formData.mobile"
                                      :placeholder="$t('globalTrans.mobile')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="12">
                                <ValidationProvider name="Applicant Designation (En)" vid="applicant_designation"  rules="required">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="applicant_designation"
                                      slot-scope="{ valid, errors }"
                                  >
                                      <template v-slot:label>
                                      {{$t('portal.applicant_designation')}} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                      id="applicant_designation"
                                      v-model="formData.applicant_designation"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="12">
                                <ValidationProvider name="Applicant Designation (Bn)" vid="applicant_designation_bn"  rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="applicant_designation_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('portal.applicant_designation')}} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="applicant_designation_bn"
                                    v-model="formData.applicant_designation_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <!-- <b-col lg="6" sm="6">
                                <ValidationProvider name="Organization"  vid="reservation_org_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="reservation_org_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="formData.reservation_org_id"
                                        :options="orgGuestList"
                                        id="reservation_address"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col> -->
                                <b-col lg="6" sm="12">
                                  <ValidationProvider name="Reservation Organization Name (En)" vid='reservation_org_name' rules="required">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="reservation_org_name"
                                          slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                          {{ $t('elearning_venue.reservation_org_name')}} {{ $t('globalTrans.enn')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                          id="reservation_org_name"
                                          v-model="formData.reservation_org_name"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                          {{ errors[0] }}
                                      </div>
                                      </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                  <ValidationProvider name="Reservation Organization Name (Bn)" vid='reservation_org_name_bn' rules="required">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="reservation_org_name_bn"
                                          slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                          {{ $t('elearning_venue.reservation_org_name')}} {{ $t('globalTrans.bnn')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                          id="reservation_org_name_bn"
                                          v-model="formData.reservation_org_name_bn"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                          {{ errors[0] }}
                                      </div>
                                      </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="6">
                                    <ValidationProvider name="Male Participent"  vid="male_participent" rules="required|min_value:0">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="male_participent"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('elearning_venue.male_participent')}}
                                        </template>
                                        <b-form-input
                                            type="number"
                                            id="male_participent"
                                            v-model="formData.male_participent"
                                            :placeholder="$t('elearning_venue.male_participent')"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="6">
                                    <ValidationProvider name="Female Participent"  vid="female_participent" rules="required|min_value:0">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="female_participent"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('elearning_venue.female_participent')}}
                                        </template>
                                        <b-form-input
                                            type="number"
                                            id="female_participent"
                                            v-model="formData.female_participent"
                                            :placeholder="$t('elearning_venue.female_participent')"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="6">
                                    <ValidationProvider name="Total Participent"  vid="total_participent" :rules="{required: true, min_value: 1, max_value: formData.total_capacity}">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="total_participent"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('elearning_venue.total_participent')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            type="number"
                                            id="total_participent"
                                            v-model="formData.total_participent"
                                            :placeholder="$t('elearning_venue.total_participent')"
                                            disabled
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                              <b-col lg="6" sm="6">
                                <ValidationProvider name="Address (En)"  vid="reservation_address">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="reservation_address"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('globalTrans.address_en')}}
                                    </template>
                                    <b-form-textarea
                                      id="reservation_address"
                                      v-model="formData.reservation_address"
                                      :placeholder="$t('globalTrans.address_en')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="6">
                                <ValidationProvider name="Address (Bn)"  vid="reservation_address_bn">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="reservation_address_bn"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('globalTrans.address_bn')}}
                                    </template>
                                    <b-form-textarea
                                      id="reservation_address_bn"
                                      v-model="formData.reservation_address_bn"
                                      :placeholder="$t('globalTrans.address_bn')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                          </div>
                        </fieldset>
                        <fieldset class="p-2 w-100">
                          <legend class="px-2 w-50 shadow-sm">{{$t('elearning_config.venue_condition')}}</legend>
                          <b-col class="col-md-12">
                            <b-row v-if="venueCondition">
                              <b-col lg="12" sm="12">
                                <span v-if="$i18n.locale === 'en'" v-html="venueCondition.condition_en"></span>
                                <span v-else v-html="venueCondition.condition_bn"></span>
                              </b-col>
                            </b-row>
                          </b-col>
                        </fieldset>
                        <b-row>
                          <b-col lg="12" sm="12" style="margin: 15px;">
                            <ValidationProvider name="Check Condition" vid="check_condition" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-for="check_condition"
                                  slot-scope="{ errors }"
                              >
                                  <b-form-checkbox
                                      id="check_condition"
                                      v-model="formData.check_condition"
                                      value="1"
                                      unchecked-value="0"
                                  >
                                    {{$t('portal.terms_condition')}}
                                  </b-form-checkbox>
                                  <div class="invalid-feedback d-block">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                              <b-button type="submit" variant="primary" class="mr-1">{{ $t('portal.submit') }}</b-button>
                              &nbsp;
                              <router-link to="/" class="btn btn-danger">{{ $t('globalTrans.cancel') }}</router-link>
                            </div>
                          </div>
                      </b-form>
                    </ValidationObserver>
                  </b-overlay>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-modal ref="mymodal" id="modal-4" size="md" :title="$t('portal.request_venue_booking')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <br/>
        <b-overlay :show="loadModal">
          <ValidationObserver ref="otp"  v-slot="{ handleSubmit, reset }">
            <b-form id="otp" @submit.prevent="handleSubmit(otpCheckData)" @reset.prevent="reset" >
              <b-row>
                <h2 class="col-lg-12 text-center" v-if="parseInt(timer) > 0">{{$t('globalTrans.timer')}} : {{ timer | minutesAndSeconds }}</h2>
                <h2 class="col-lg-12 text-center" v-else>{{$t('portal.please_resent_otp')}}</h2>
                <b-col lg="12" sm="12">
                  <b-col lg="12" sm="12">
                    <ValidationProvider name="Otp"  vid="otp" rules="required">
                      <div slot-scope="{ valid, errors }">
                        <b-form-input
                          id="otp"
                          v-model="otpCheck"
                          :placeholder="$t('globalTrans.otp')"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </b-col>
                </b-col>
                <br/>
                <b-col lg="12" sm="12" style="padding-left: 30px;padding-top:10px">
                  <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.verify') }}</b-button>
                  <!-- <b-button type="button" variant="primary" class="mr-2 lg-3" @click="otpCheckData">{{ $t('globalTrans.verify') }}</b-button> -->
                  <b-button type="button" variant="warning" class="mr-2 lg-3" @click="reOtpSend">{{ $t('globalTrans.resend_code') }}</b-button>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-overlay>
      <br/>
    </b-modal>
    <b-modal id="modal-5" size="lg" :title="$t('elearning_config.contact_person_info') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
       <ContactDetails :venueId="venueId" :orgId="orgId"></ContactDetails>
    </b-modal>
  </div>
</template>
<style>
  #form fieldset {
    border: 1px solid #8f7c7c!important;
    float: left!important;
    margin-bottom: 25px!important;
    padding: 10px 0!important;
    width: 99.5%!important;
  }
  #form legend {
    background: #02813e none repeat scroll 0 0!important;
    border: 1px solid #e5e5e5!important;
    color: #fff!important;
    font-size: 13px!important;
    font-weight: 700!important;
    margin-bottom: 0!important;
    margin-left: 14px!important;
    padding: 4px 10px!important;
    width: 31%!important;
    border-radius: 0!important;
  }
  .alert .close {
    color: red !important;
  }
</style>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { authServiceBaseUrl, commonServiceBaseUrl, trainingElearningServiceBaseUrl } from '../../../../config/api_config'
import Common from '@/mixins/portal/common'
import flatpickr from 'flatpickr'
import ContactDetails from './ContactDetails'
const TWO_MINITES = 60 * 2

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    ContactDetails
  },
  mixins: [Common],
  created () {
    this.getFiscalYearList()
  },
  data () {
    return {
      load: false,
      loadModal: false,
      commonServiceBaseUrl: commonServiceBaseUrl,
      saveBtnName: this.$t('portal.submit'),
      formData: {
        id: '',
        circular_memo_no: '',
        it_cafeteria: 0,
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        venue_id: 0,
        slot_id: 0,
        total_capacity: '',
        total_participent: 0,
        male_participent: 0,
        female_participent: 0,
        applicant_designation: '',
        applicant_designation_bn: '',
        rent_amount: '',
        electricity_bill: 0,
        gas_bill: 0,
        address: '',
        contact_person_no: '',
        start_date: '',
        end_date: '',
        check_condition: '',
        reservation_org_name: '',
        reservation_org_name_bn: '',
        reservation_org_id: 0,
        gov_private: 1,
        status: 1
      },
      booking: true,
      officeTypeList: [],
      officeList: [],
      venuList: [],
      venueCondition: '',
      otp: '',
      reload: false,
      showMessage: false,
      otpCheck: '',
      venus: [],
      countShow: false,
      TWO_MINITES: TWO_MINITES,
      timer: 0,
      venueId: 0,
      orgId: 0,
      gascheck: 0,
      tmpFiscalYearList: [],
      reservationDataList: [],
      fiscalYearList: []
    }
  },
  filters: {
    minutesAndSeconds (value) {
      var minutes = Math.floor(parseInt(value, 10) / 60)
      var seconds = parseInt(value, 10) - minutes * 60
      return `${minutes}:${seconds}`
    }
  },
  computed: {
    slotList: function () {
        return [
            { value: 1, text: this.$i18n.locale === 'en' ? '9.00 - 1.00' : '৯.০০ - ১.০০' },
            { value: 2, text: this.$i18n.locale === 'en' ? '2.00 - 5.00' : '২.০০ -৫. ০০' },
            { value: 3, text: this.$i18n.locale === 'en' ? 'Full Day' : 'সারা দিন' }
        ]
    },
    orgList: function () {
      return this.$store.state.Portal.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    orgGuestList: function () {
      const orgData = this.$store.state.Portal.commonObj.organizationProfileList.filter(item => item.status === 0)
      orgData.push(
        {
          value: 999,
          text: 'Other',
          text_en: 'Other',
          text_bn: 'অন্যান্য'
        }
      )
      return orgData
    },
    govOrPvt: function () {
      const govOrPvt = [
        {
          value: 1,
          text: 'Govt',
          text_en: 'Govt',
          text_bn: 'সরকারী'
        },
        {
          value: 2,
          text: 'Private',
          text_en: 'Private',
          text_bn: 'বেসরকারী'
        }
      ]
      const userType = [
        {
          value: 1,
          text: 'NARS Institute',
          text_en: 'NARS Institute',
          text_bn: 'এনএআরএস ভূক্ত প্রতিষ্ঠান'
        },
        {
          value: 2,
          text: 'Govt. Organization/University',
          text_en: 'Govt. Organization/University',
          text_bn: 'সরকারি সংস্থা/সরকারি বিশ্ববিদ্যালয়'
        },
        {
          value: 3,
          text: 'Private University/Organization',
          text_en: 'Private University/Organization',
          text_bn: 'বেসরকারি বিশ্ববিদ্যালয়/সংস্থা'
        },
        {
          value: 4,
          text: 'Institute Scientist/Officer/Employee/Labourer Son/daughter except other use',
          text_en: 'Institute Scientist/Officer/Employee/Labourer Son/daughter except other use',
          text_bn: 'ইনস্টিটিউটের বিজ্ঞানী/কর্মকর্তা/কর্মচারী/শ্রমিক পুত্র/কন্যা ছাড়া অন্যান্য ব্যবহার'
        },
        {
          value: 5,
          text: 'Only Agricultural Organization',
          text_en: 'Only Agricultural Organization',
          text_bn: 'শুধুমাত্র কৃষি বিষয়ক প্রতিষ্ঠান'
        }
      ]
      if (this.formData.org_id !== 9) {
        const tmpList = govOrPvt.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn, text_en: obj.text_en, text_bn: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en, text_en: obj.text_en, text_bn: obj.text_bn }
          }
        })
        return tmpList
      } else {
        const tmpList = userType.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn, text_en: obj.text_en, text_bn: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en, text_en: obj.text_en, text_bn: obj.text_bn }
          }
        })
        return tmpList
      }
    },
    // fiscalYearList: function () {
    //   return this.$store.state.Portal.commonObj.fiscalYearList.filter(item => item.status === 0)
    // },
    venusDatas: function () {
      const tmpList = this.venus.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.venu_name_bn, text_en: obj.venu_name, text_bn: obj.venu_name_bn, org_id: obj.org_id }
        } else {
            return { value: obj.id, text: obj.venu_name, text_en: obj.venu_name, text_bn: obj.venu_name_bn, org_id: obj.org_id }
        }
      })
      return tmpList
    }
  },
  watch: {
    'formData.venue_id': function (newValue) {
      this.getVenueInfo(newValue)
    },
    'formData.fiscal_year_id': function (newValue) {
      this.getVenueCondition(newValue)
    },
    'formData.org_id': function (newValue) {
      this.officeTypeList = this.getOfficeTypeList(newValue)
      this.getVenueCondition(newValue)
    },
    'formData.office_type_id': function (newValue) {
      this.officeList = this.getOfficeList(newValue)
    },
    'formData.office_id': function (newValue) {
      this.getVenusList(newValue)
    },
    'formData.gov_private': function (newValue) {
       this.getVenueInfo(this.formData.venue_id)
    },
    'formData.male_participent': function (newValue) {
       this.getTotalParticipent()
    },
    'formData.female_participent': function (newValue) {
       this.getTotalParticipent()
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fiscalYearList = this.fiscalYearLocale(this.tmpFiscalYearList)
      }
    },
    'formData.start_date': function (newVal, oldVal) {
      if (newVal !== oldVal) {
       this.getReservationData(this.formData.venue_id)
      }
    },
    'formData.end_date': function (newVal, oldVal) {
      if (newVal !== oldVal) {
       this.getReservationData(this.formData.venue_id)
      }
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
    setInterval(() => {
      this.timer -= 1
      if (this.timer === 0) {
        this.otpDelete()
      }
    }, 1000)
  },
  methods: {
    getTotalParticipent () {
      this.formData.total_participent = parseInt(this.formData.male_participent) + parseInt(this.formData.female_participent)
    },
    fiscalYearLocale (data) {
      return data.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    fiscalYearBnAdd (data) {
      const bnList = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯']
      const bnAdded = data.map(item => {
        const fiscalYearData = {}
        const result = item.text_bn.split('').map(item => item === '-' ? '-' : bnList[item])
        fiscalYearData.text_bn = result.join('')
        return Object.assign({}, item, fiscalYearData)
      })
      return bnAdded
    },
    getCurrentFiscalYearId (fiscalYearList) {
      if (fiscalYearList.length === 0) {
        return
      }
      const currentDate = new Date()
      const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
      /** Define the year position  */
      const yearPosition = month < 7 ? 5 : 0
      const yearStr = `${year}`
      let currentFiscalYearId = 0
      fiscalYearList.forEach(element => {
        if (element.text_en.indexOf(yearStr) === yearPosition) {
          currentFiscalYearId = element.value
        }
      })
      return currentFiscalYearId
    },
    async getFiscalYearList () {
      const result = await RestApi.getData(commonServiceBaseUrl, 'fiscal-year-list', null)
      if (result.success) {
        const dataList = this.fiscalYearBnAdd(result.data)
        this.tmpFiscalYearList = dataList
        this.fiscalYearList = this.fiscalYearLocale(dataList)
        this.formData.fiscal_year_id = this.getCurrentFiscalYearId(dataList)
        // this.getVenueCondition()
      }
    },
    async getVenueInfo (venueId) {
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, 'config/master-venue-management/show' + '/' + venueId)
      if (!result.success) {
        this.formData.total_capacity = 0
        this.formData.rent_amount = 0
        this.formData.address = 0
        this.formData.address_bn = 0
        this.formData.contact_person_no = 0
      } else {
        const trData = result.data
        this.formData.total_capacity = trData.total_capacity
        if (this.formData.gov_private === 1) {
          this.formData.rent_amount = trData.rent_amount
        } else {
          this.formData.rent_amount = trData.rent_amount_pvt
        }
        this.formData.address = trData.address
        this.formData.address_bn = trData.address_bn
        this.formData.contact_person_no = trData.contact_person_no
        this.formData.electricity_bill = trData.electricity_bill
        this.formData.gas_bill = trData.gas_bill
        this.formData.it_cafeteria = trData.it_cafeteria
        if (parseInt(trData.it_cafeteria) === 1) {
          this.gascheck = 1
        } else {
          this.gascheck = 0
        }
      }
    },
    async getReservationData (venueId) {
      // if (this.formData.start_date && this.formData.end_date) {
        const params = {
          start_date: this.formData.start_date,
          end_date: this.formData.end_date
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, 'venue/venue-reservation/reservation-data' + '/' + venueId, params)
        if (!result.success) {
          this.reservationDataList = []
        } else {
          this.reservationDataList = result.data
        }
      // }
    },
    async getVenueCondition () {
      const params = {
        fiscal_year_id: this.formData.fiscal_year_id,
        org_id: this.formData.org_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, 'config/venue-condition/show-data', params)
      if (!result.success) {
        this.venueCondition = ''
      } else {
        this.venueCondition = result.data
      }
    },
    async otpCheckData () {
      this.loadModal = true
      let result = null
      const ottpSend = {
        mobile: this.formData.mobile,
        otp: this.otpCheck
      }
      result = await RestApi.postData(trainingElearningServiceBaseUrl, 'external-user/venue-reservation/ottp-check', ottpSend)
      if (result.success) {
        this.createData()
        this.loadModal = false
      } else {
        this.otpCheck = ''
        this.loadModal = false
        this.$toast.error({
          title: 'Error',
          message: 'Not Matched'
        })
        this.$refs.form.setErrors(result.errors)
      }
    },
    async otpDelete () {
      let result = null
      const ottpSend = {
        mobile: this.formData.mobile,
        otp: this.otpCheck
      }
      result = await RestApi.postData(trainingElearningServiceBaseUrl, 'external-user/venue-reservation/ottp-delete', ottpSend)
      if (result.success) {
      } else {
        this.$toast.error({
          title: 'Error',
          message: 'Not Matched'
        })
      }
    },
    async otpSend () {
      this.loadModal = true
      this.load = true
      let result = null
      result = await RestApi.postData(trainingElearningServiceBaseUrl, 'external-user/venue-reservation/ottp', this.formData)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.a_otp_send_your_mobile'),
          color: '#D6E09B'
        })
        this.$refs.mymodal.show()
        this.timer = this.TWO_MINITES
        this.loadModal = false
        this.load = false
      } else {
        this.loadModal = false
        this.load = false
        if (!result.booking) {
          this.booking = false
          this.$toast.error({
            title: 'Error',
            message: this.$t('elearning_venue.this_vanue_is_reserved_in_this_date_and_time'),
            color: '#D6E09B'
          })
        } else {
          this.$refs.form.setErrors(result.errors)
        }
      }
    },
    async reOtpSend () {
      this.loadModal = true
      let result = null
      result = await RestApi.postData(trainingElearningServiceBaseUrl, 'external-user/venue-reservation/ottp', this.formData)
      if (result.success) {
        this.timer = this.TWO_MINITES
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.a_otp_send_your_mobile'),
          color: '#D6E09B'
        })
        this.loadModal = false
      } else {
        this.$refs.form.setErrors(result.errors)
        this.loadModal = false
      }
    },
    async createData () {
      this.load = true
      let result = null
      result = await RestApi.postData(trainingElearningServiceBaseUrl, 'external-user/venue-reservation/store', this.formData)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$router.push({
          path: '/portal/venue-reservation-details',
          query: { id: result.data.id }
        })
        // this.$refs.mymodal.hide()
        // this.showMessage = true
        // this.$router.push('/portal/services')
        // this.$refs.form.reset()
        this.load = false
        this.otp = ''
      } else {
        if (!result.booking) {
          this.booking = false
          this.$toast.error({
            title: 'Error',
            message: this.$t('elearning_venue.this_vanue_is_reserved_in_this_date_and_time'),
            color: '#D6E09B'
          })
        } else {
          this.$refs.form.setErrors(result.errors)
        }
      }
    },
    async loadUser (users) {
      this.userLoad = true
      const result = await RestApi.getData(authServiceBaseUrl, '/auth/portal/get-users', { user_ids: JSON.stringify(users) })
      this.userLoad = false
      if (result.success) {
       this.users = result.data
      }
    },
    async getVenusList () {
      this.loading = true
      const search = {
        org_id: this.formData.org_id,
        office_id: this.formData.office_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, '/config/master-venue-management/venue-list', search)
      this.loading = false
      if (result.success) {
        this.venus = result.data
      } else {
        this.venus = []
      }
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.Portal.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.Portal.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    details (orgId, venueId) {
      this.orgId = orgId
      this.venueId = venueId
    }
  }
}
</script>
